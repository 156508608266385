import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../images/icons/p27.svg";
import sdk_img from "../images/sdk.svg";
import adress_book_img from "../images/adress-book.svg";
import sdk_network_img from "../images/sdk-network.svg";
import crediflow_sdk_img from "../images/crediflow-sdk.svg";

const BOT_CODE = "l8QZLG6U";

function Sdk() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_boka_demo_1, set_chatbot_boka_demo_1] = useState(false);
    const [chatbot_boka_demo_2, set_chatbot_boka_demo_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>SDK | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow är en Plattformsgodkända accesspunktsoperatörer inom SDK - Säker Digital Kommunikation." />
                <meta name="keywords" content="SDK" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="SDK - Säker Digital Kommunikation" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/sdk" />
                <link rel="cannonical" href="https://www.crediflow.se/sdk" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Säker digital kommunikation</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        SDK (Säker digital kommunikation) är ett säkert 
                                        sätt att utbyta känslig och sekretessklassad 
                                        information mellan kommuner, regioner, statliga 
                                        myndigheter och andra offentligt finansierade 
                                        aktörer. Det är ett säkert alternativ 
                                        till fax och vanliga brev.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-2/3 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">SDK</span>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-full mx-auto">
                            <img className="w-full" src={crediflow_sdk_img} alt="Skicka e fakura" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h2 className="title-font font-semibold text-3xl text-gray-900">Godkänd Accesspunktsoperatör inom SDK</h2>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Crediflow är en Plattformsgodkänd 
                                accesspunktsoperatörer. Vår Accespunkt är 
                                en molnbaserat tjänst där vi ansvarar för 
                                drift, underhåll, uppdateringar samt uppdaterar vår tjänst 
                                enligt DIGGs krav och riktlinjer.
                            </p>
                            <h3 className="font-bold text-gray-900 mt-12">Plattformsgodkänd accesspunktsoperatör</h3>
                            
                            <div className="mt-2 grid 2xl:grid-cols-3 gap-1">
                                <div className="grid grid-cols-2 2xl:grid-cols-1 border border-gray-400">
                                    <p className="bg-gray-200 p-2 text-gray-800 ">Accesspunktsoperatör</p>
                                    <p className="bg-white p-2 text-gray-600">Crediflow AB</p>
                                </div>
                                <div className="grid grid-cols-2 2xl:grid-cols-1 border border-gray-400">
                                    <p className="bg-gray-200 p-2 text-gray-800 ">Datum för godkännande</p>
                                    <p className="bg-white p-2 text-gray-600">2022-06-29</p>
                                </div>   
                                <div className="grid grid-cols-2 2xl:grid-cols-1 border border-gray-400">
                                    <p className="bg-gray-200 p-2 text-gray-800 ">Transportmodeller</p>
                                    <p className="bg-white p-2 text-gray-600">Bas och Utökad bas</p>
                                </div>
                            </div>
                            <button onClick={() => set_chatbot_boka_demo_2(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            
                            {chatbot_boka_demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>

                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-300"/>
                </div>
            </section>

            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-12 gap-20">
                        <div className="lg:col-span-7 flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Vad är SDK (Säker digital kommunikation)?</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Varje dag skickas mängder av information mellan 
                                aktörer i offentlig sektor, i handläggningen 
                                av ärenden och beslut. Ofta handlar det om 
                                sekretessklassad information som vårdplaner, 
                                behandlingsplaner, bedömningar av arbetsförmåga 
                                och utdrag ur belastningsregistret. I brist på 
                                säkrare alternativ används ofta fax eller vanliga 
                                brev, särskilt inom hälso- och sjukvården, skolan och socialtjänsten.
                                <br/><br/>
                                Säker digital kommunikation är en digital infrastruktur 
                                som gör det möjligt för kommuner, regioner, statliga myndigheter 
                                och andra offentligt finansierade aktörer att utbyta känslig och 
                                sekretessklassad information digitalt på ett tryggt, enkelt och säkert sätt.
                                <br/><br/>
                                Meddelanden som skickas via Säker digital kommunikation kan 
                                innehålla fritext och bilagor i pdf-format. Meddelandena adresseras 
                                till funktionsadresser, exempelvis till en viss enhet på ett 
                                äldreboende eller en avdelning på ett sjukhus. Det går inte att skicka 
                                meddelanden till enskilda personer.
                            </p>
                            <button onClick={() => set_chatbot_boka_demo_1(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            
                            {chatbot_boka_demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="lg:col-span-5 w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={sdk_img} alt="Skicka e fakura" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-300"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <h2 className="title-font font-semibold text-3xl text-gray-900 md:w-2/3 lg:w-1/2 2xl:w-1/3 mx-auto text-center">Säker digital kommunikation bidrar till följande nytta</h2>
                    <div className="pt-24 grid md:grid-cols-2 lg:grid-cols-4 gap-12 lg:gap-4">
                        <div className="bg-white rounded-md shadow-md">
                            <div className="-mt-4 flex justify-center items-center">
                                <span className="w-16 h-16 bg-green-custome text-white block flex items-center justify-center rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-7 w-7" viewBox="0 0 16 16">
                                        <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765l-6-3.2ZM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516ZM0 13.373l5.693-3.163L0 6.873v6.5Z"/>
                                    </svg>
                                </span>
                            </div>
                            <p className="p-6 text-gray-900">Minskad beroende av fax och vanliga brev för överföring av känslig information.</p>
                        </div>

                        <div className="bg-white rounded-md shadow-md">
                            <div className="-mt-4 flex justify-center items-center">
                                <span className="w-16 h-16 bg-green-custome text-white block flex items-center justify-center rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-7 w-7" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z"/>
                                    </svg>
                                </span>
                            </div>
                            <p className="p-6 text-gray-900">Trygghet genom att känslig information inte sprids till obehöriga.</p>
                        </div>

                        <div className="bg-white rounded-md shadow-md">
                            <div className="-mt-4 flex justify-center items-center">
                                <span className="w-16 h-16 bg-green-custome text-white block flex items-center justify-center rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-7 w-7" viewBox="0 0 16 16">
                                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                                    </svg>
                                </span>
                            </div>
                            <p className="p-6 text-gray-900">Möjlighet till samma spårbarhet oavsett vilken verksamhet som skickar informationen.</p>
                        </div>

                        <div className="bg-white rounded-md shadow-md">
                            <div className="-mt-4 flex justify-center items-center">
                                <span className="w-16 h-16 bg-green-custome text-white block flex items-center justify-center rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-7 w-7" viewBox="0 0 16 16">
                                        <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                        <path fill-rule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"/>
                                    </svg>
                                </span>
                            </div>
                            <p className="p-6 text-gray-900">Snabbare handläggning och beslut.</p>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="py-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex flex-col items-center justify-center mx-auto lg:w-2/3 2xl:w-1/2">
                        <h2 className="title-font font-semibold text-3xl text-gray-900 lg:text-center">Hur fungerar Säker digital kommunikation?</h2>
                        <p className="leading-relaxed mt-8 text-lg text-gray-600 lg:text-center">
                            Säker digital kommunikation är en digital infrastruktur 
                            utan eget användargränssnitt. Användarna skapar och 
                            tar emot meddelanden i organisationens befintliga lokala system.
                        </p>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-4 mt-12">
                        <div className="bg-gray-100 rounded-lg overflow-hidden">
                            <div className="w-full pt-10 mx-auto flex justify-center items-center">
                                <img className="w-1/2" src={adress_book_img} alt="SDK Adressbok" />
                            </div>
                            <div className="p-10">
                                <h3 className="leading-relaxed text-lg text-gray-900 font-bold">Adresserna hanteras i SDK Adressbok</h3>
                                <p className="leading-relaxed mt-2 text-lg text-gray-600">
                                    För att kunna skicka och ta emot meddelanden via 
                                    SDK så behöver adresserna till både avsändare och 
                                    mottagare finnas registrerade i SDK adressbok. 
                                    Varje organisation ansvarar för att utse administratörer 
                                    som uppdaterar den egna organisationens funktionsadresser 
                                    i SDK Adressbok och tilldelar behörighet till de 
                                    personer som ska kunna skicka och läsa meddelanden.
                                </p>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg overflow-hidden">
                            <div className="w-full pt-10 mx-auto flex justify-center">
                                <img className="w-1/2" src={sdk_network_img} alt="vem levererar SDK" />
                            </div>
                            <div className="p-10">
                                <h3 className="leading-relaxed text-lg text-gray-900 font-bold">SDK levereras av Inera och Digg</h3>
                                <p className="leading-relaxed mt-2 text-lg text-gray-600">
                                    De organisationer som ansluter till SDK organiseras i en 
                                    så kallad federation. En federation är ett nätverk där de 
                                    deltagande organisationerna är överens om gemensamma spelregler 
                                    inom teknik och informationssäkerhet och därför kan utbyta 
                                    information med varandra. Inera är så kallad federationsägare 
                                    och ansvarar för att godkänna mjukvaran hos de organisationer 
                                    som ansluter till SDK. Digg ansvarar för att godkänna 
                                    anslutningarna av de lokala systemen till den 
                                    gemensamma infrastrukturen för SDK.
                                </p>
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>

            


        </Layout>
    );
}
export default Sdk;